

:root {
  --black: #424240;;
  --white: #ffffff;
  --primary: #431D93;
  --darkPrimary: #23c66b;
  --secondary: #22BBDD;
  --grey: #A1A4AB;
  --lightGrey: #C9CBD0;
  --lightestGrey: #E9EAEC;
  --darkGrey: #646972;
  --green: #2BD978;
  --orange: #FFC260;
  --darkOrange: #ffb43c;
  --red: #FF5C93;
  --blue: #22BBDD;
  --butterflyBlue:  #3699FF;
  --mustard:#E8A562;
}
