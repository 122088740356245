@import '../../../globalStyles.scss';

.keyValue {
  flex-grow: 1;
  flex-shrink: 0;
  overflow: hidden;
}

@media (max-width: $xl-breakpoint) {
  .keyValue {
    overflow: visible;
  }
}
