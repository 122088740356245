/* textColors */
$textColor: var(--black);

/* colors */
$primaryColor: var(--primary);
$darkerPrimaryColor: var(--darkPrimary);
$secondaryColor: var(--secondary);
$whiteColor: var(--white);
$alertColor: var(--red);
$successColor: var(--green);
$greyColor: var(--grey);
$lightGreyColor: var(--lightGrey);
$lightestGreyColor: var(--lightestGrey);
$darkGreyColor: var(--darkGrey);
$blackColor: var(--black);
$greenColor: var(--green);
$redColor: var(--red);
$orangeColor: var(--orange);
$darkerOrangeColor: var(--darkOrange);
$ButterflyBlue:  var(--butterflyBlue);
$mustardColor: var(--mustard);

$imageFallbackBackgroundColor: var(--lightestGrey);

/* fontFamilies */
$fontFamilyNormal: Rubik;
$fontFamilyBold: RubikBold;
$commandFontFamily: SourceCodePro;

/* breakpoints */
$md-breakpoint: 768px;
$lg-breakpoint: 992px;
$xl-breakpoint: 1200px;

/* fontSizes */
$fontSizeH1: 6.8rem;
$fontSizeH2: 4.2rem;
$fontSizeH3: 2.6rem;
$fontSizeH4: 1.6rem;
$fontSizeBody: 1.6rem;
$fontSizeSmall: 1.4rem;
$fontSizeTiny: 1.2rem;

/* lineHeights */
$lineHeightH1: 7.2rem;
$lineHeightH2: 4.8rem;
$lineHeightH3: 4.8rem;
$lineHeightH4: 2.4rem;
$lineHeightBody: 1.9rem;
$lineHeightSmall: 2.4rem;
$lineHeightTiny: 1.6rem;

/* inputs */
$inputHeight: 4rem;
$inputBgColor: var(--white);
$inputBorderRadius: 4px;
$inputBorderColor: var(--lightGrey);
$inputFontSize: 1.6rem;
$inputTextColor: var(--black);
$inputPlaceholderColor: var(--grey);

/* buttons */
$buttonBorderRadius: 4px;
$buttonFontSize: 1.6rem;
$buttonHeight: 4rem;

/* spacing */
$spacing-xs: 4px;
$spacing-sm: 8px;
$spacing-md: 16px;
$spacing-xl: 32px;
$spacing-xxl: 40px;

$headerHeight: 5.6rem;
