@import '../../globalStyles.scss';

.datePickerField {
  height: 4rem;
  background-color: $whiteColor;
  border: 1px solid var(--lightGrey);
  box-sizing: border-box;
  display: flex;
  width: 146px !important;
  font-size: 1.6rem;
  color: $blackColor;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  outline: 0;
  margin-top: 23px;
  line-height: 37px;
}

.filterplaceholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: #A1A4AB;
}

.tile {
   height: 28px;
   background-color: #EDE4FF; 
   margin-top: -5px !important; 
   margin-left: 7px !important;
   border-radius: 4px; 
   padding: 5px 11px !important
}

.removeIcon {
    margin-top: 23px !important;
    padding-top: 4px !important;
    width: 34px;
    height: 40px;
    border: 1px solid #C9CBD0;
    border-top-right-radius: 4px; 
    border-bottom-right-radius: 4px   
}